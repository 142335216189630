import React, { useState } from 'react';
import logo from '../logo.svg';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined,
} from '@ant-design/icons';
import { Button, Layout, Menu, theme, ConfigProvider } from 'antd';
const { Header, Sider, Content } = Layout;

function AuthLayouts() {
    const [collapsed, setCollapsed] = useState(false);
    const { token: { colorBgContainer, borderRadiusLG } } = theme.useToken();

    const handClick = ({  key})=>{
        
    }
    return (
        <ConfigProvider
            theme={{
                token: {
                    // Seed Token
                    colorPrimary: 'red',
                    borderRadius: 2,
                    // Alias Token
                    colorBgContainer: '#f6ffed',
                },
            }}
        >
            <Layout style={{ height: "100vh" }}>
                <Sider theme='light' trigger={null} collapsible collapsed={collapsed}>
                    <img src={logo} className="demo-logo-vertical" />
                    <Menu mode="inline" defaultSelectedKeys={['1']} items={[
                        {
                            key: 1,
                            icon: <UserOutlined />,
                            label: 'nav 1',
                            onClick: handClick,
                            
                        },
                        {
                            key: 2,
                            icon: <VideoCameraOutlined />,
                            label: 'nav 2',
                            onClick: handClick
                        },
                        {
                            key: 3,
                            icon: <UploadOutlined />,
                            label: 'nav 3',
                            onClick: handClick
                        },
                    ]}
                    />
                </Sider>
                <Layout>
                    <Header
                        style={{
                            padding: 0,
                            background: colorBgContainer,
                        }}
                    >
                        <Button
                            type="text"
                            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                            onClick={() => setCollapsed(!collapsed)}
                            style={{
                                fontSize: '16px',
                                width: 64,
                                height: 64,
                            }}
                        />
                    </Header>
                    <Content
                        style={{
                            margin: '24px 16px',
                            padding: 24,
                            minHeight: 280,
                            background: colorBgContainer,
                            borderRadius: borderRadiusLG,
                        }}
                    >
                        Content
                    </Content>
                </Layout>
            </Layout>
        </ConfigProvider>

    )
}

export default AuthLayouts